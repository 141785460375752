// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")
global.jQuery = require("jquery")
global.$ = jQuery
require('jquery-ui-dist/jquery-ui')
require("@fortawesome/fontawesome-free");
require ("moment")
require('bootstrap');
require("bootstrap-datepicker")
require('@popperjs/core')
require('inputmask');
require('./nested-forms/add_fields');
require('./nested-forms/remove_fields');
require('@client-side-validations/client-side-validations')


// load legacy non-webpacker javascript
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/slimscroll/jquery.slimscroll.min.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/metisMenu/jquery.metisMenu.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/pace/pace.min.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/chartjs/Chart.min.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/reports_kit/vendor/daterangepicker.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/reports_kit/vendor/jquery.tablesorter.min.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/reports_kit/vendor/select2.full.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/reports_kit/lib/_init.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/reports_kit/lib/chart.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/reports_kit/lib/report.js")
require("/var/sites/vendors.jcs-inc.org/releases/20240928160655/app/assets/javascripts/reports_kit/lib/table.js")






